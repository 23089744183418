import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useAdjustmentsList() {
  // Use toast
  const toast = useToast();

  const refAdjustmentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "ref", label: i18n.t("Transaction id"), stickyColumn: true },
    { key: "username", label: i18n.t("Username"), stickyColumn: true },
    { key: "staff", label: i18n.t("Staff Created") },
    // { key: "currency", label: i18n.t("Currency") },
    { key: "type", label: i18n.t("Adjustment type") },
    { key: "amount", label: i18n.t("Amount") },
    // { key: "batch_adjustment_name", label: "Batch Adjustment Name" },
    { key: "note", label: i18n.t("Remark") },
    { key: "status", label: i18n.t("Status") },
    { key: "created_at", label: i18n.t("Created at") },
    // { key: "updated_at", label: i18n.t("Updated at") },
    { key: "action", stickyColumn: true, label: i18n.t("Actions"), class: "" },
  ];
  const statusOptions = ref([]);
  const perPage = ref(25);
  const totalMoney = ref(0);

  const totalAdjustments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const statusFilter = ref(null);
  const typeFilter = ref(null);
  const currencyFilter = ref(null);
  const remarkFilter = ref();

  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const referenceFilter = ref(null);
  const currencyOptions = ref([]);
  const remarkOptions = ref([]);

  const refetchData = () => {
    refAdjustmentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      searchQuery,
      usernameFilter,
      statusFilter,
      typeFilter,
      currencyFilter,
      remarkFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchAdjustments = (ctx, callback) => {
    store
      .dispatch("payment-adjustment/fetchAdjustments", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        username: usernameFilter.value,
        status: statusFilter.value,
        type: typeFilter.value,
        currency: currencyFilter.value,
        note: remarkFilter.value,
        reference: referenceFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
      })
      .then((response) => {
        const { adjustments, total, pageSize, money } = response;
        callback(adjustments);
        totalAdjustments.value = total;
        perPage.value = pageSize;
        totalMoney.value = money;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching adjustments list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refAdjustmentListTable.value
      ? refAdjustmentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAdjustments.value,
    };
  });

  // store.dispatch("local-setting-currency/fetchCurrencies").then((response) => {
  //   if (response.data.code == "00") {
  //     currencyOptions.value = response.data.data;
  //   }
  // });
  store
    .dispatch("local-setting-currency/fetchAdjustBalanceReason")
    .then((response) => {
      console.log(123, response);
      remarkOptions.value = response.data?.data.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });
  store
    .dispatch("local-setting-currency/fetchOptionStatus")
    .then((response) => {
      statusOptions.value = response.data?.data.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });

  const resolveType = (type) => {
    if (type === 1) return { label: "Credit", variant: "success" };
    if (type === 2) return { label: "Debit", variant: "warning" };
    return { label: "None", variant: "secondary" };
  };

  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
    return { label: "None", variant: "secondary" };
  };

  const typeOptions = [
    { label: "Credit", value: "1" },
    { label: "Debit", value: "2" },
  ];

  return {
    fetchAdjustments,
    tableColumns,
    perPage,
    currentPage,
    totalAdjustments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAdjustmentListTable,

    resolveType,
    refetchData,
    resolveStatus,

    typeOptions,
    statusOptions,
    currencyOptions,

    // Extra Filters
    usernameFilter,
    statusFilter,
    typeFilter,
    currencyFilter,
    remarkFilter,
    referenceFilter,
    fromdateFilter,
    todateFilter,
    remarkOptions,
    totalMoney,
  };
}
