<template>
  <b-card-actions :title="$t('Filter')" action-collapse :collapsed="false">
    <b-row class="filter">
      <!-- Username Filter -->
      <b-col cols="12" md="3" class="mb-2">
        <label>{{ $t("Login Name") }}</label>
        <b-form-input
          v-model="filter.username"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Transaction Type Filter -->
      <b-col cols="12" md="3" class="mb-md-0 mb-2">
        <label>{{ $t("Adjustment type") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.type"
          :options="typeOptions"
          class="w-100"
          :reduce="(val) => val.value"
        />
        <!-- @input="(val) => $emit('update:typeFilter', val)" -->
      </b-col>
      <!-- Currency Filter -->
      <!-- <b-col cols="12" md="3" class="mb-md-0 mb-2">
        <label>{{ $t("Currency") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.currency"
          :options="currencyOptions"
          class="w-100"
          :reduce="(val) => val.id"
        @input="(val) => $emit('update:currencyFilter', val)"
          label="value"
        />
      </b-col> -->
      <!-- Reference Filter -->
      <b-col cols="12" md="3" class="mb-2">
        <label>{{ $t("Transaction id") }}</label>
        <b-form-input
          v-model="filter.reference"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- From Date Fiter -->
      <b-col cols="12" md="3" class="mb-2">
        <b-form-group label="Created From" label-for="fromDate">
          <flat-pickr
            id="fromDate"
            v-model="filter.fromdate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
          <!-- @on-change="changeFromDate" -->
        </b-form-group>
      </b-col>
      <!-- To Date Filter -->
      <b-col cols="12" md="3" class="mb-2">
        <b-form-group label="Created To" label-for="toDate">
          <flat-pickr
            id="toDate"
            v-model="filter.todate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
            @on-change="changeToDate"
          />
        </b-form-group>
      </b-col>
      <!-- Status Filter -->
      <b-col cols="12" md="3" class="mb-md-0 mb-2">
        <label>{{ $t("Status") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.status"
          :options="statusOptions"
          class="w-100"
          :reduce="(val) => val.value"
          :selectable="(item) => item.label !== filter.status"
        />
        <!-- @input="(val) => $emit('update:statusFilter', val)" -->
      </b-col>

      <b-col cols="12" md="3" class="mb-md-0 mb-2">
        <label>{{ $t("Remark") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.remark"
          :options="remarkOptions"
          class="w-100"
          :reduce="(val) => val.label"
        />
        <!-- :selectable="(item) => item.label !== filter.status" -->
        <!-- @input="(val) => $emit('update:statusFilter', val)" -->
      </b-col>
      <!-- Action button -->
      <b-col cols="12" md="3" class="d-flex align-items-end justify-content-start pb-20">
        <b-button variant="outline-secondary" @click="resetFilter()">
          <feather-icon icon="RefreshCwIcon" size="16" /> {{ $t("Reset") }}
        </b-button>
        <b-button variant="primary" class="ml-2" @click="searchFilter()">
          <feather-icon icon="SearchIcon" size="16" /> {{ $t("Search") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BCardActions,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardBody,
    BCardText,
    vSelect,
    flatPickr,
  },
  props: {
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [String, null],
      default: null,
    },
    currencyFilter: {
      type: [Number, null],
      default: null,
    },
    remarkFilter: {
      type: [Number, null],
      default: null,
    },
    referenceFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
    // currencyOptions: {
    //   type: Array,
    //   default: null,
    // },
    typeOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    remarkOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: {
        username: this.usernameFilter,
        type: this.typeFilter,
        currency: this.currencyFilter,
        reference: this.referenceFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
        status: this.statusFilter,
        remark: this.remarkFilter,
      },
      flatpickrConfig: {
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
      },
    };
  },
  methods: {
    changeFromDate() {
      this.$emit("update:fromdateFilter", this.filter.fromdate);
    },
    changeToDate() {
      this.$emit("update:todateFilter", this.filter.todate);
    },
    resetFilter() {
      this.filter = {
        username: null,
        type: null,
        currency: null,
        reference: null,
        fromdate: null,
        todate: null,
        status: null,
        remark: null,
      };
      this.$emit("update:usernameFilter", null);
      this.$emit("update:typeFilter", null);
      this.$emit("update:currencyFilter", null);
      this.$emit("update:referenceFilter", null);
      this.$emit("update:fromdateFilter", null);
      this.$emit("update:todateFilter", null);
      this.$emit("update:statusFilter", null);
      this.$emit("update:remarkFilter", null);
    },
    searchFilter() {
      this.$emit("update:usernameFilter", this.filter.username);
      this.$emit("update:typeFilter", this.filter.type);
      this.$emit("update:currencyFilter", this.filter.currency);
      this.$emit("update:referenceFilter", this.filter.reference);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:statusFilter", this.filter.status);
      this.$emit("update:remarkFilter", this.filter.remark);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
