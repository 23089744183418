/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <adjustments-list-filters
      :username-filter.sync="usernameFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :type-filter.sync="typeFilter"
      :type-options="typeOptions"
      :currency-filter.sync="currencyFilter"
      :remark-filter.sync="remarkFilter"
      :currency-options="currencyOptions"
      :remark-options="remarkOptions"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :reference-filter.sync="referenceFilter"
    />
    <b-card no-body class="mb-0">
      <div cols="12" class="d-flex justify-content-end">
        <div v-if="$can('import', 'adjustbalance')" class="m-1">
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="primary"
                @click="showModalAddBatchAdjustment()"
              >
                <feather-icon icon="DownloadIcon" size="16" />{{ $t("Import") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div class="m-1" v-if="$can('export', 'adjustbalance')">
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="primary"
                @click="exportData"
                v-if="!showLoadingExport"
              >
                <feather-icon icon="UploadIcon" size="16" /> {{ $t("Export") }}
              </b-button>
              <b-button v-else variant="primary" disabled class="mr-1">
                <b-spinner small />
                <feather-icon icon="DownloadIcon" size="16" />
                {{ $t("Exporting") }}
              </b-button>
              <div class="hidden">
                <vue-export-excel
                  ref="exportButton"
                  :data="data"
                  :columns="columns"
                  :file-name="exportFiletitle"
                  :file-type="'xlsx'"
                  :sheet-name="exportFileSheetName"
                >
                  <feather-icon icon="DownloadIcon" size="16" />
                  {{ $t("Export") }}
                </vue-export-excel>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div style="margin: 8px 0px; color: white">
        Tổng tiền:
        {{ totalMoney ? numberFormat(parseFloat(totalMoney) / 1000) : 0 }}
      </div>
      <b-table
        ref="refAdjustmentListTable"
        sticky-header
        head-variant="light"
        class="position-relative table-white-space max-height-table"
        :no-border-collapse="true"
        :items="fetchAdjustments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(ref)="data">
          <b-link @click="showAdjustmentDetail(data.item)">
            {{ data.item.ref }}
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
          >
            {{ data.item.user ? data.item.user.username : "" }}
          </b-link>
        </template>

        <template #cell(fullname)="data">
          {{ data.item.user ? data.item.user.fullName : "" }}
        </template>
        <template #cell(staff)="data">
          {{ data.item.staff ? data.item.staff.username : "" }}
        </template>

        <template #cell(currency)="data">
          {{ data.item.user ? data.item.user.currency : "VND" }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />
            {{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(amount)="data">
          <span class="text-primary">{{
            numberFormat(data.item.amount / 1000)
          }}</span>
        </template>

        <template #cell(batchAdjustmentName)="data">
          {{ data.item.batchAdjustmentName || "" }}
        </template>

        <template #cell(type)="data">
          <b-badge
            pill
            :variant="`light-${resolveType(data.item.type).variant}`"
            class="text-capitalize"
          >
            {{ resolveType(data.item.type).label }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-success
            :title="$t('Approve')"
            v-if="
              data.item.txn_status.id === 2 &&
              $can('update', 'adjustbalancestatus')
            "
            variant="success"
            class="btn-icon btn-sm mr-50"
            @click="approveAdjustmentModal(data.item, 4)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="
              data.item.txn_status.id === 2 &&
              $can('update', 'adjustbalancestatus')
            "
            variant="danger"
            class="btn-icon btn-sm mr-50"
            @click="approveAdjustmentModal(data.item, 3)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Detail')"
            variant="outline-secondary"
            class="btn-icon btn-sm"
            @click="showAdjustmentDetail(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAdjustments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Approve Deposit Modal -->
    <approve-adjustment-modal
      :adjustment-data.sync="adjustmentData"
      :type.sync="type"
      @refetch-data="refetchData"
    />
    <!-- Detail Modal -->
    <adjustment-detail-modal :adjustment-data.sync="adjustmentData" />
    <!-- Import Batch Adjustment -->
    <import-batch-adjustment-modal @refetch-data="refetchData" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BBadge,
  BSpinner,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  formatDateTime,
  resolveCurrency,
} from "@core/utils/filter";
import store from "@/store";
import AdjustmentsListFilters from "./AdjustmentsListFilters.vue";
import useAdjustmentsList from "./useAdjustmentsList";
import adjustmentStoreModule from "./adjustmentStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";
import VueExportExcel from "@/views/extensions/export/VueExportExcel.vue";

export default {
  components: {
    AdjustmentsListFilters,
    ApproveAdjustmentModal: () => import("./ApproveAdjustmentModal.vue"),
    AdjustmentDetailModal: () => import("./AdjustmentDetailModal.vue"),
    ImportBatchAdjustmentModal: () =>
      import("./ImportBatchAdjustmentModal.vue"),
    VueExportExcel,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BBadge,
    BSpinner,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      adjustmentData: null,
      type: null,
      showLoadingExport: false,
      columns: [
        { label: "St", field: "id" },
        { label: "Transaction ID", field: "ref" },
        { label: "Username", field: "user", dataFormat: this.showUsername },
        {
          label: "Created People",
          field: "staff",
          dataFormat: this.showUsername,
        },
        {
          label: "Adjustment type",
          field: "type",
          dataFormat: (value) => this.resolveType(value).label,
        },
        { label: "Remark", field: "note" },
        {
          label: "Amount",
          field: "amount",
          dataFormat: (value) => value,
        },
        {
          label: "Status",
          field: "status",
          dataFormat: (value) => this.resolveStatus(value).label,
        },
        {
          label: "Created At",
          field: "created_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
        // {
        //   label: "Updated At",
        //   field: "updated_at",
        //   dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        // },
      ],
      data: [],
      exportFiletitle: `Adjustment-${new Date()
        .toJSON()
        .replace(":", "-")
        .substring(0, 16)}`,
      exportFileSheetName: "Adjustment",
    };
  },
  methods: {
    approveAdjustmentModal(adjustmentData, type) {
      this.adjustmentData = adjustmentData;
      this.type = type;
      this.$bvModal.show("modal-approve-adjustment");
    },
    showAdjustmentDetail(adjustmentData) {
      this.adjustmentData = adjustmentData;
      this.$bvModal.show("modal-adjustment-detail");
    },
    showModalAddBatchAdjustment() {
      this.$bvModal.show("modal-import-batch-adjustment");
    },
    showUsername(value) {
      return value ? value.username : "";
    },
    showName(value) {
      return value ? value.name : "";
    },
    async getExportData() {
      await store
        .dispatch("payment-adjustment/exportAdjustment", {
          // page: pageNumber,
          type: this.typeFilter,
          username: this.usernameFilter,
          reference: this.referenceFilter,
          fromdate: this.fromdateFilter,
          todate: this.todateFilter,
          bankId: this.bankFilter,
          stat: this.statusFilter,
        })
        .then((response) => {
          if (response.data.code == "00") {
            this.data = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          // const { deposits } = response
          // this.data.push(...deposits)
        });
    },
    async exportData() {
      this.showLoadingExport = true;
      // this.data = []
      // const totalPage = Math.ceil(this.totalDeposits / this.perPage)
      // for (let i = 1; i <= totalPage; i++) {
      await this.getExportData();
      await this.delay(2000);
      // }
      this.showLoadingExport = false;
      this.$refs.exportButton.$el.click();
    },
    delay(ms) {
      return new Promise((resolve, reject) => setTimeout(resolve, ms));
    },
  },
  setup() {
    const PAYMENT_ADJUSTMENT_STORE_MODULE_NAME = "payment-adjustment";
    // Register module
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_ADJUSTMENT_STORE_MODULE_NAME,
        adjustmentStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_ADJUSTMENT_STORE_MODULE_NAME);
    });

    const {
      fetchAdjustments,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdjustmentListTable,
      refetchData,

      // UI
      resolveType,
      resolveStatus,

      typeOptions,
      statusOptions,
      currencyOptions,
      remarkOptions,

      // Extra Filters
      usernameFilter,
      statusFilter,
      typeFilter,
      currencyFilter,
      remarkFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      totalMoney,
    } = useAdjustmentsList();

    return {
      fetchAdjustments,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdjustmentListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,
      currencyOptions,
      remarkOptions,

      // UI
      resolveType,
      resolveStatus,

      typeOptions,
      statusOptions,

      // Extra Filters
      usernameFilter,
      statusFilter,
      typeFilter,
      currencyFilter,
      remarkFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      totalMoney,
    };
  },
};
</script>
