/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from "@core/utils/filter";
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class AdjustmentService {
  getAdjustmentLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      currency: queryParams.currency,
      phone: queryParams.phone,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      balanceId: queryParams.balanceId,
      type: queryParams.type,
      note: queryParams.note,
      stat: queryParams.status,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/adjust-balance/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const {
          q = "",
          perPage = 10,
          page = 1,
          sortBy = "id",
          sortDesc = false,
        } = queryParams;
        const queryLowered = q.toLowerCase();
        const filteredData = response.data.data.filter(
          (adjustment) =>
            adjustment.ref.toLowerCase().includes(queryLowered) ||
            adjustment.user.username.toLowerCase().includes(queryLowered)
        );
        const sortedData = filteredData.sort(sortCompare(sortBy));
        if (sortDesc) sortedData.reverse();

        const datas = {
          response,
          adjustments: queryLowered
            ? paginateArray(sortedData, perPage, page)
            : response.data.data,
          total: queryLowered ? filteredData.length : response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
          money: response.data.total,
        };
        return datas;
      });
  }

  getUserLogin(queryParams) {
    const pars = {
      // username: queryParams.username,
      userId: queryParams.userId,
      // email: queryParams.email,
      // name: queryParams.fullname,
      // currency: queryParams.currency,
      // phone: queryParams.phone,
      // reference: queryParams.reference,
      // fromDate: queryParams.fromdate,
      // toDate: queryParams.todate,
      // balanceId: queryParams.balanceId,
      // type: queryParams.type,
      // stat: queryParams.status,
      pageNumber: queryParams.page,
      // pageSize: queryParams.perPage,
    };
    return instance.get("/api/User/login-log", {
      headers: authHeader(),
      params: pars,
    });
  }

  addAdjustment(adjustmentData) {
    const FormData = require("form-data");
    const datas = new FormData();
    /* datas.append(
			'models[0]',
			`'{
				"userId":${adjustmentData.userId},
				"type":${adjustmentData.type},
				"amount":${adjustmentData.amount},
				"note":${adjustmentData.note},
				"reason":${adjustmentData.reason}
			}'`) */
    /* datas.append('models[0].userId', adjustmentData.userId)
		datas.append('models[0].type', adjustmentData.type)
		datas.append('models[0].amount', adjustmentData.amount)
		datas.append('models[0].note', adjustmentData.note) */
    datas.append("user_info", adjustmentData.userId);
    datas.append("type", adjustmentData.type);
    datas.append("amount", adjustmentData.amount * 1000);
    datas.append("note", adjustmentData.note);

    return instance.post("/api/adjust-balance/create", datas, {
      headers: authHeader(),
    });
  }

  updateAdjustmentStatus(adjustmentData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("adjust_balance_id", adjustmentData.id);
    data.append("status", adjustmentData.status);
    data.append("reason", adjustmentData.reason);

    return instance.post("/api/adjust-balance/update-status", data, {
      headers: authHeader(),
    });
  }

  importAdjustment(adjustmentData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("file", adjustmentData.attachment);
    return instance
      .post("/api/adjust-balance/import", data, { headers: authHeader() })
      .then();
  }
  // exportAdjustment
  exportAdjustment(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      currency: queryParams.currency,
      phone: queryParams.phone,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      balanceId: queryParams.balanceId,
      type: queryParams.type,
      stat: queryParams.status,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    const data = {};
    return instance.post("/api/adjust-balance/export", data, {
      headers: authHeader(),
      params: pars,
    });
  }
}
// getAdjustBalanceReason

export default new AdjustmentService();
