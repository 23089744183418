import AdjustmentService from '@/libs/adjustment.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchAdjustments(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				AdjustmentService.getAdjustmentLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchUserLogin(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				AdjustmentService.getUserLogin(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addAdjustment(ctx, adjustmentData) {
			return new Promise((resolve, reject) => {
				AdjustmentService.addAdjustment(adjustmentData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateAdjustmentStatus(ctx, adjustmentData) {
			return new Promise((resolve, reject) => {
				AdjustmentService.updateAdjustmentStatus(adjustmentData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		importAdjustment(ctx, adjustmentData) {
			return new Promise((resolve, reject) => {
				AdjustmentService.importAdjustment(adjustmentData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		exportAdjustment(ctx, adjustmentData) {
			return new Promise((resolve, reject) => {
				AdjustmentService.exportAdjustment(adjustmentData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		// fetchAdjustBalanceReason(ctx, queryParams) {
		// 	return new Promise((resolve, reject) => {
		// 		AdjustmentService.getAdjustBalanceReason(queryParams)
		// 			.then(response => resolve(response))
		// 			.catch(error => reject(error))
		// 	})
		// },
		
	}
}
